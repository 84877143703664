import { createApp } from 'vue'
import store from './store'
import App from './App.vue'
import router from './router'
import JQuery from 'jquery'
import communicator from './helpers/communicator'
import cache from './helpers/cache'
import colors from './helpers/colors'
import apiErrorhandler from './helpers/apiErrorhandler'
import adaptHtml from './helpers/adaptHtml'
import formatters from './helpers/formatters'
import message from './helpers/message'
import directives from './helpers/directives'
import  './assets/css/global.css'
import  './assets/css/theme.css'
import  './assets/css/directives.css'
import  './assets/css/mobile.css'
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css'


window.$ = JQuery

async function initApp() {
    
    const app = createApp(App);

    var settings = await communicator.get("settings-default/");
    store.commit('setEntity', { entityName: 'settings', entity: settings })
    
    //add globals
    app.config.globalProperties.$communicator = communicator
    app.config.globalProperties.$cache = cache
    app.config.globalProperties.$formatters = formatters
    app.config.globalProperties.$message = message
    app.config.globalProperties.$colors = colors
    app.config.globalProperties.$apiErrorhandler = apiErrorhandler
    app.config.globalProperties.$adaptHtml = adaptHtml
    app.config.globalProperties.$isMobile = window.innerWidth < 1000
    app.config.globalProperties.$windowHeight = window.innerHeight

    app.directive('date', directives.date)
    app.directive('tooltip', directives.tooltip)
    app.directive('hover', directives.hover)
    app.directive('fade-in', directives.fadeIn)
    app.directive('html-to-text', directives.htmlToText)
    app.component('QuillEditor', QuillEditor)

    app.use(store).use(router).mount('#app');
}

initApp();
