<script>
import menuLinks from "@/helpers/menuLinks"
import Logo from '@/components/Logo.vue'
import Notification from '@/components/Notification.vue'
export default {
    components:{ Logo, Notification },
    props: ['noOfUserMessages'],
    data(){
        return {
            showMobileMenu: false,
            customer: this.$store.state.customer,
            menuVisible: false,          
        }
    },
    computed:
    {
        user()
        {
            return this.$store.state.user;
        },
        isAdmin()
        {
            return this.user.user_level == 'SamfallifyCrew' || this.user.user_level == 'Redaktör'
        },
        height() { return $(window).height() },
        links() {
       
          var links = menuLinks.getLinks(this.$store.state.settings);
          if(this.isPartOfBoard && this.$store.state.settings.BoardMember.enabled)
          {
            links.push({ viewName: 'canvasBoard', icon: 'fa-solid fa-people-line', text: 'Styrelsen' });
          }
          if(this.$store.state.settings.WorkGroup.enabled)
          {
            this.userWorkGroups.forEach(function(workGroup) {
              links.push({ viewName: 'canvasWorkGroup', params: {id: workGroup.id}, icon: 'fa-solid fa-person-digging', text: workGroup.name });
            });
          }
          links.push({ viewName: 'userMessages', icon: 'fa-solid fa-envelope', text: 'Meddelanden' });
          links.push({ viewName: 'userSettings', icon: 'fa-solid fa-gears', text: 'Inställningar'  });     
          links.push({ viewName: 'logout', icon: 'fa-solid fa-door-open', text: 'Logga ut' },);
          return links;

        },
        isPartOfBoard()
        {
            return this.$store.state.boardMembers != null ? this.$store.state.boardMembers.some(x => x.user_id == this.user.id) : false;
        },   
        userWorkGroups() {  
            return this.$store.state.userWorkGroups != null ? this.$store.state.userWorkGroups : []; 
        }, 
        notifications() {        
            return this.$store.state.notifications != null ? this.$store.state.notifications : []; 
        }      
    },
    methods:
    {
        goTo(viewName)
        {        
            this.menuVisible = false;
            this.$router.push({ name: viewName })
        } ,
        goTopage(link, bookingItem)
        {
            this.showMobileMenu = false;
            $(window).scrollTop(0);     
            this.$router.push({ name: link.viewName, params: link.params })           
        }   
    },
    created()
    {
      this.$cache.createCache('boardMembers', 'board-members/') 
    }
}
</script>
<template>
    <section class="MobileMenu" v-fade-in>
      <div class="MobileMenu-logo" @click="goTo('home')">                 
        <img src="@/assets/imgs/new_logo.png" style="width: 82px;margin-bottom: -4px;">               
        <div style="max-width: 70%">{{customer.name}}</div>
    </div>
      <div class="MobileMenu-icon">   
        <div class="MobileMenu-icon-notification" v-if="!showMobileMenu && notifications.length > 0">{{ notifications.length }}</div>  
        <div v-if="noOfUserMessages > 0 && !showMobileMenu" class="MobileMenu-no-of-new-messages"> {{ noOfUserMessages }}</div>
        <p @click="showMobileMenu = !showMobileMenu">             
          <hr :class="showMobileMenu ? 'MobileMenu-line-fade-out' : 'MobileMenu-line-fader'">
          <hr :class="showMobileMenu ? 'MobileMenu-line-x' : 'MobileMenu-line-x-straight'">
          <hr :class="showMobileMenu ? 'MobileMenu-line-y' : 'MobileMenu-line-y-straight'">
          <hr :class="showMobileMenu ? 'MobileMenu-line-fade-out' : 'MobileMenu-line-fader'">        
          <b v-if="!showMobileMenu">MENY</b>
        </p>     
      </div>
      <div v-if="showMobileMenu">       
          <div class="MobileMenu-links" :style="{ 'height': height + 'px' }">
            <div class="MobileMenu-item" @click="goTopage(link)" v-for="link in links">
              <Notification :level="'menu'" :levelName="link.text" :notifications="notifications" :color="'#d98df6'" :left="'10px'" :top="'-3px'"></Notification>
              <div v-if="link.viewName == 'userMessages' && noOfUserMessages > 0" class="MobileMenu-no-of-new-messages" style="left: 10px; top: -4px;"> {{ noOfUserMessages }}</div>
               <i :class="link.icon"></i> &nbsp; {{ link.text.replace('-<br>','') }}
            </div>
            
            <div class="MobileMenu-bg">
              <img src="../assets/imgs/new_logo.png" style="opacity: 0.2; width: 130px">  
            </div>
          </div>  
          <div class="MobileMenu-triangle">
          </div>
        </div>  
    </section>
  </template>
  
<style scoped>
  .MobileMenu-icon
  {
    overflow: auto; 
    position: absolute; 
    z-index: 100; 
    right: 10px;
    top: 0px;
  }

  .MobileMenu-icon-notification
  {
    position: absolute;
    top:8px;
    right: 21px;
    height: 17px;
    width: 20px;
    background-color: #d98df6;
    border-radius: 10px;
    font-size: 9pt;
    padding-top: 3px;
    text-align: center;
    color: white;

   }
  .MobileMenu-links
  {
    position: absolute; 
    width:calc(100% - 140px); 
    z-index: 10; 
    top: 0px;  
    padding-top: 50px;
  }
  .MobileMenu
  { 
    color: black;
    position: fixed;
    z-index: 1000;
    top: 0px;
    right: 0px;
  }
    .MobileMenu b {
      color: white;
      text-shadow: -1px -1px 0 rgb(0, 0, 0), 1px -1px 0 rgb(0, 0, 0), -1px 1px 0 rgb(0, 0, 0), 1px 1px 0 rgb(0, 0, 0);
    } 
    .MobileMenu p 
    {
      font-size: 11pt;
      font-weight: bold;
      text-align: center;
      margin: 17px 30px;
      width: 45px;
      float: right;
      cursor: pointer;
    }  
    .MobileMenu hr {
      height: 5px;
      width: 40px;
      margin: 2px;
      float: right;
      background-color: white;
      border: 1px solid black;
      border-radius: 3px;
    }  
  .MobileMenu-line-fade-out
  {
    opacity: 0;
    transition: 0.3s;
  }
  .MobileMenu-line-x
  {
    transform: rotate(135deg);
    background-color: black !important;
    border: 1px solid black !important;
    transition: 0.3s;
  }
  .MobileMenu-line-y
  {
    transform: rotate(45deg);
    transition: 0.3s;
    background-color: black !important;
    border: 1px solid black !important;
    position: absolute;
    top: 28px;
    right: 30px;
  }
  .MobileMenu-line-y-straight
  {
    transform: rotate(0deg);
    transition: 0.3s;
    position: absolute;
    top: 28px;
    right: 30px;
  }
  .MobileMenu-line-x-straight
  {
    transform: rotate(180deg);
    transition: 0.3s;
  }
  .MobileMenu-line-fader
  {
    opacity: 1;
    transition: 0.6s;
  }
  .MobileMenu-item
  {
    font-size: 15pt;
    font-weight: bold;
    text-transform: uppercase;
    text-align: left;
    padding: 10px 20px;
    cursor: pointer;
    filter: brightness(90%);
    width: 100%;
    position: relative;
  }
  .MobileMenu-triangle {
    width: 0;
    height: 0;
    border-bottom: 1500px solid transparent;
    border-left: 274px solid transparent;
    position: absolute;
    top: 0px;
    right: 0px;
  }
  .MobileMenu-bg
  {
    width:150px;
    height: 150px;
    position:absolute;
    bottom: 80px;
    left: 0px;
    right: 0px;
    margin: auto;
  
  }

  .MobileMenu-logo {
    margin: 2px 0px 0px 0px;
    text-align: left;      
    font-family: 'Titan One', cursive;
    text-transform: uppercase;
    font-weight: normal;
    font-size: 14px;
    color: white;
    padding:10px 20px;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    cursor: pointer;
    position: relative;
    }

    .MobileMenu-no-of-new-messages
    {
        background-color: #00ffa1;
        position: absolute;
        top:8px;
        right: 40px;
        height: 17px;
        width: 20px;
        border-radius: 10px;
        font-size: 9pt;
        padding-top: 3px;
        text-align: center;
      
    }
  </style>