<script>
import PopUp from '@/components/PopUp.vue'
import Form from '@/components/Form/Form.vue'
import YesNoPopUp from '@/components/YesNoPopUp.vue'
import message from '@/helpers/message'

export default {
    components:{ Form, PopUp, YesNoPopUp},
    props: ['workGroupId', 'members', 'updateChat'],
    data() {
        return {  
            isBoard: this.workGroupId == undefined,
            canvasMessage: {id: 0, message: '', is_board: this.workGroupId == undefined, work_group_id: this.workGroupId, is_file_info: false },
            isSaving: false,
            groupedChat: null,
            editItem: null,
            deleteItem: null,
            form: [{ sections: [
                        { sectionName: 'Redigera', classes: "g-100", inputs: [
                            { type: 'textArea', prop: 'message', title: 'text', classes: "g-100", required: true }, 
                         
                            ]
                        }]
                    }],
        }
    },
    created() {
        this.getChat();
    },
    methods: {
        getChat() {
            var self = this;
            
            var url = this.isBoard ? 'canvas-chat-board/' : 'canvas-chat-work-group/' + this.workGroupId;
            this.$communicator.get(url) 
            .then(function(data)
            {
                self.groupedChat = Object.groupBy(data, ({ date_for_sorting }) => date_for_sorting);
                setTimeout(() => {     
                    var objDiv = document.getElementById("CanvasCommunicate-chat-area");
                    objDiv.scrollTop = objDiv.scrollHeight;
                }, 200);
                self.editItem = null;
                self.deleteItem = null;
            })
            .fail(function(error) { 
                self.$apiErrorhandler.handleErrors(error, self.$route.fullPath)              
            }) 
        }, 
        send()
        {
            if(this.canvasMessage.message == '')
            {
                return;
            }
            this.isSaving = true;
            var self = this;
            this.$communicator.post('canvas-chat/', this.canvasMessage)
                .then(function(data)
                {
                    self.canvasMessage = {id: 0, message: '', is_board: self.isBoard,  work_group_id: self.workGroupId };
                    self.groupedChat = Object.groupBy(data, ({ date_for_sorting }) => date_for_sorting);
                    setTimeout(() => {     
                        var objDiv = document.getElementById("CanvasCommunicate-chat-area");
                        objDiv.scrollTop = objDiv.scrollHeight;
                    }, 200);

                    
                })
                .fail(function(error) {            
                    self.isSaving = false;
                })
                .done(function(data) {     
                    self.isSaving = false;
                })   
        },
        delete(){
            var self = this;
            this.$communicator.post('canvas-chat/' + this.deleteItem.id + '/delete')
                .then(function(data)
                {                 
                    self.getChat();
                    message.setSuccessMessage();
                })
                .fail(function(error) {                  
                }) 
        },
        cancelDelete() {
            this.deleteItem = null;
        }
    },  
    computed:
    {
        height() { return window.innerHeight - 225 },
        chatWindowHeight() { return window.innerHeight - 366 + 'px' },
    },
    watch: {
        workGroupId(newvalue){
            this.getChat();
        },
        updateChat: {
            handler(newValue, oldValue) {
                if(newValue.timeToUpdate)
                    this.getChat();
                this.updateChat.timeToUpdate = false;              
            },
            deep: true
        }      
    }
}
</script>
<template>
    <section v-fade-in :style="{ 'height': height + 'px' }">    
     <div class="g-100">
        <h2>Chatten</h2>
        <div id="CanvasCommunicate-chat-area" class="position-relative" style="overflow-y: scroll; border: 1px solid #ddd; background-color: white;" :style="{ 'height': chatWindowHeight }">
            <div v-for="(group,index) in groupedChat" :key="index">
                <div class="CanvasCommunicate-date-header"><p>{{ index }}</p></div>
                <div v-for="message in group" class="CanvasCommunicate-chat-item" :key="message.id">    
                    <div>
                        <b class="font-color">{{ message.created_by_full_name }}</b> {{ message.created_date_formatted }} 
                        <i v-if="!message.is_file_info" class="fa-solid fa-pen cursor-pointer" v-tooltip="'Redigera'" @click="editItem = message"></i>&nbsp;
                        <i v-if="!message.is_file_info" class="fa-solid fa-trash cursor-pointer" v-tooltip="'Ta bort'" @click="deleteItem = message"></i>
                        <div class="CanvasCommunicate-message">
                            <div style="white-space: pre-wrap;" v-html="message.message"></div>
                        </div>                            
                    </div>
                </div>
            </div>
        </div>   
     </div>
    <div style="">      
        <div class="g-100">
            <div class="g-90">
                <textarea v-model="canvasMessage.message" placeholder="Skriv meddelande...."></textarea>
            </div>
            <div class="g-10">
            <button class="btn" @click="send">Skicka</button>
            </div>          
        </div>
    </div>
    </section>
    <div v-if="editItem != null">
        <PopUp>
            <Form :form="form" :item="editItem" :url="'canvas-update-chat/'" :getItemFunction="getChat" :inEditMode="true"></Form>
        </PopUp>
    </div>
    <div v-if="deleteItem != null">
        <YesNoPopUp :yes="delete" :no="cancelDelete" :text="'Är du säker på att du vill ta bort?'"></YesNoPopUp>       
    </div>
</template>
<style scoped>
section{
    position: relative;
}

button
{
    width: 100%;
    height: 100px;
}

textarea{
    height:86px;
}

pre {
    margin: 0px;
}

.CanvasCommunicate-chat-item{
    overflow: auto;
    padding:10px 0px;
    background: white;
    display: block;
    text-decoration: none;
    margin: 0px 10px;
}

.CanvasCommunicate-message
{
    margin-top:5px;
}

.CanvasCommunicate-date-header
{
    border-top: 1px solid #ddd; 
    margin: 20px 10px 0px 10px;
}
.CanvasCommunicate-date-header p
{
    text-align: center; 
    font-weight: bold;
}

#CanvasCommunicate-member-area
{
    line-height: 30px;
}

@media only screen and (max-width: 1000px) {
  button
  {
    width: 100%;
    height: 50px;
    margin-top: -10px;
  }

  
    .CanvasCommunicate-chat-item
    {
        font-size: 11pt;
    }
}
</style>