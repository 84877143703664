<script>
export default {
    props: ['width', 'bgColor', 'closePopup'],
    data(){
        return {
           areaWidth: this.width == undefined || this.$isMobile ? '80%' : this.width,
           height: $(window).height() - 100 + 'px'
        }
    },
    methods:
    {
        handleChildClick(event) {
            event.stopPropagation(); 
        },
        handleKeyDown(event) {
            console.log(event.key);
            if (event.key === "Escape") {
                console.log('hej');
                this.closeP();         
            }
        },
        closeP() {
            if(this.closePopup != undefined)
            {
                this.closePopup();
            }
        }
    },
    mounted() {
        window.addEventListener('keydown', this.handleKeyDown);
    },
    beforeDestroy() {
        window.removeEventListener('keydown', this.handleKeyDown);
    }   
}
</script>
<template>
<div class="PopUp-bg" v-fade-in="200" @click="closeP">
    <div class="PopUp-area" :style="{ 'width': areaWidth, 'max-height': height, 'background-color': bgColor }"  @click="handleChildClick">
        <slot></slot> <!-- slot outlet -->
    </div>
</div>
</template>

<style scoped>
    .PopUp-bg
    {
        position: fixed;
        left: 0px;
        top: 0px;
        width:100%;
        height:2000px;
        background-color: rgba(0,0,0,0.6);
        z-index:1001;
    }

    .PopUp-area
    {
        position: absolute;
        top: 50px;
        width: 80%;
        margin: auto;
        left: 0px;
        right: 0px;
        background-color: white;
        padding:20px;
        overflow-y: auto;
    }

    @media only screen and (max-width: 1000px) {
    .PopUp-area
    {
        top: 30px;
    }
}
</style>