import { createStore } from 'vuex'

const store = createStore({
    state: {
      user: null,
      members: null,
      boardMembers: null,
      forums: null,
      modules: null,
      customer: null,
      users: null,
      notifications: null,
      news: null,
      bookings: null,
      availableBookings: null,
      invitations: null,
      message: null,
      userMessages: null,
      documents: null,
      images: null,
      workGroups: null,
      userWorkGroups: null,
      recipientGroups: null,
      incomingEvents: null,
      statutes: null,
      rulesOfProcedure: null,
      accountSettings: null,
      userInstructions: null,
      settings: null,
    },
    mutations: {
      setEntity(state, payload){
          state[payload.entityName] = payload.entity;
      },
      
    }  
  })

export default store
