<script>
    import ListFilter from './ListFilter.vue';
    import YesNoPopUp from '@/components/YesNoPopUp.vue';
    import PopUp from '@/components/PopUp.vue';
    import UserMessageSender from '@/components/UserMessageSender.vue'
    export default {
        props: ['items', 'columns', 'id', 'height','hideSearch', 'noOfFrozenColumns','nameOfItems','isEditing', 'sorting', 'backgroundColor', 'borderColor', 'deleteFunction', 'iconView', 'dropdowns'],
        data() {
            return {
                name: '',
                tableWidth: '',
                searchString: '',
                filters: {},
                listItems: [],
                noOfFrozenCols: this.noOfFrozenColumns != undefined && !this.$isMobile ? this.noOfFrozenColumns : 0,
                frozenColumns: [],
                stickyColumns: [],
                sortedByColumn: null,
                showSortingWindow: false,
                useSorting: this.sorting != undefined,
                isAscending: true,
                deleteItem: null,
                userMessage: null,
                dayOfWeek: ["Måndag", "Tisdag", "Onsdag", "Torsdag", "Fredag", "Lördag", "Söndag"]
            }
        },
        components: { ListFilter, YesNoPopUp, UserMessageSender, PopUp },
        computed: {
            noOfItemsDisplayed() {
                return this.listItems.filter(p => p.IncludedInFilter).length;
            },
            tableHeight() {
                if (this.height == undefined) {
                    return "500px";
                }
                else {
                    return this.height + "px";
                }
            },
            user() {return this.$store.state.user; }
        },
        created() {
            var self = this;
            //populate list items
            var rowId = 1;
            this.items.forEach(function (item) {
                item.IncludedInFilter = true;
                item.rowId = rowId++;
                self.listItems.push(item);
            });

            var width = this.columns
                .map(x => parseInt(x.width) + 16)
                .reduce((partialSum, a) => partialSum + a, 0);

            this.tableWidth = width + "px";

            this.frozenColumns = [];
            this.stickyColumns = [];
            var colIndex = 0;
            var marginLeft = 0;
            this.columns.forEach(function (col) {
                if (colIndex < self.noOfFrozenCols) {
                    col.marginLeft = marginLeft + "px";
                    col.left = marginLeft + 10 + "px";
                    marginLeft += col.width;
                    self.frozenColumns.push(col);
                }
                else {
                    self.stickyColumns.push(col);
                }
                colIndex++;
            });  
            
            this.filterList();
        },
        methods: {
            search(e) {
                if (e.which === 27) {
                    e.currentTarget.value = '';
                    this.searchString = '';
                }

                this.filterList();
            },

            filterList() {
                var self = this;
                for (var i = 0; i < this.listItems.length; i++) {
                    var item = this.listItems[i];

                    if (this.searchString !== '') {
                        //check if searchstring matches.
                        var matchesWithSearchString = this.columns
                            .map(function (column) {
                                return item[column.name] != null && item[column.name].toString().toLowerCase().includes(self.searchString.toLowerCase());
                            });

                        if (!matchesWithSearchString.includes(true)) {
                            item.IncludedInFilter = false;
                            continue;
                        }
                    }

                    var isIncludedInFilters = [];
                    for (var prop in item) {
                        if (this.filters[prop] && this.filters[prop].length > 0) {

                            // Check if item value includes at least one selected filter.
                            let valueIncludesFilter = (item[prop] != null && item[prop] != undefined ? item[prop] : '')
                                .toString()
                                .split('|')
                                .map(t => t.trim())
                                .some(s => this.filters[prop].includes(s));

                            if (valueIncludesFilter) {
                                isIncludedInFilters.push(true);
                            }
                            else {
                                isIncludedInFilters.push(false);
                            }
                        }
                    }

                    if(this.dropdowns != undefined)
                    {
                        this.dropdowns.forEach(function(dropdown) {
                            
                            if(dropdown.value != null && dropdown.value != item[dropdown.prop])
                            {
                                isIncludedInFilters.push(false);
                            }
                        });
                    }

                    item.IncludedInFilter = !isIncludedInFilters.includes(false);
                }
            },
            orderList()
            {
                var self = this;
                var dateTypes = ['created_date','formatted_start_date_and_time']
                if(this.sortedByColumn.name == 'board_member_type')
                {
                    var boardMemberTypes = this.$store.state.categories.filter(x => x.prop_name == 'board_member_type').map(x => x.name);
                    var list = [];
                    var types = this.isAscending ? boardMemberTypes.reverse() : boardMemberTypes;
                   
                    types.forEach(function(type) {
                        for(var i = 0; i < self.listItems.length; i++)
                        {
                            if(self.listItems[i].board_member_type == type)
                            {
                                list.push(self.listItems[i]);
                            }
                        }
                    })
                    
                    this.listItems = list;
                }
                else if(dateTypes.some(x => x == this.sortedByColumn.name))
                {
                    if(this.isAscending)
                    {
                        this.listItems = this.listItems.sort(function (a, b) {
                            return  new Date(a[self.sortedByColumn.name]) > new Date(b[self.sortedByColumn.name]) ? 1 : -1;
                        })
                    }
                    else
                    {
                        this.listItems = this.listItems.sort(function (a, b) {
                            return  new Date(a[self.sortedByColumn.name]) < new Date(b[self.sortedByColumn.name]) ? 1 : -1;
                        })
                    }
                }
                else
                {
                    if(this.isAscending)
                    {
                        this.listItems = this.listItems.sort(function (a, b) {
                            return  a[self.sortedByColumn.name].toLowerCase() < b[self.sortedByColumn.name].toLowerCase() ? 1 : -1;
                        })
                    }
                    else
                    {
                        this.listItems = this.listItems.sort(function (a, b) {
                            return  a[self.sortedByColumn.name].toLowerCase() > b[self.sortedByColumn.name].toLowerCase() ? 1 : -1;
                        })
                    }
                }

                this.showSortingWindow = false;

            },   
            sortBy(column)
            {
                this.sortedByColumn = column;
            },
            showDeletePopUp(item)
            {
                
                this.deleteItem = item;
            },
            remove()
            {
                var id = this.deleteItem.id;
                this.deleteFunction(id);
                this.deleteItem = null;
            },
            cancelRemove()
            {
                this.deleteItem = null;
            },
            sendUserMessage(user)
            {
                this.userMessage = { id: 0, to_user_id: user.id, subject: '', message: '', is_read: false, toUserName: user.first_name + ' ' + user.last_name }
            },
            closeUserMessageSender()
            {
                this.userMessage = null;
            },
            changeDropdownValue(dropdown, value)
            {
                dropdown.value = value;
                this.filterList();
            }
        },
        watch: {
            items() {
                var updatedList = [];

                var rowId = 1;
                this.items.forEach(function (item) {
                    item.IncludedInFilter = true;
                    item.rowId = rowId++;
                    updatedList.push(item);
                });

                this.listItems = [];
                setTimeout(() => {
                    this.listItems = updatedList;
                }, 10);
                this.filterList();
            }
        }
    }
</script>

<template>
    <div class="row">
        <div class="overflow-auto">
            <input v-if="!hideSearch" class="List-search" type="text" v-model="searchString" @keyup="search" placeholder="Skriv in ett sökord..." />
            <div style="display: inline-block;" v-if="useSorting">
                <button id="List-sort-btn" class="btn" @click="showSortingWindow = !showSortingWindow">
                    <span v-if="sortedByColumn == null">Sortera</span>
                    <span v-else>Sorterad efter {{ sortedByColumn.displayName }} | {{ isAscending ? 'Stigande': 'Fallande' }}</span>
                </button>
            </div>
            <div style="display: inline-block;" v-if="dropdowns != undefined">
                <div v-for="(dropdown, index) in dropdowns" :key="index">
                    <button class="btn" v-for="(availableItem, index) in dropdown.availableItems" :key="index" :class="availableItem.id == dropdown.value ? '' : 'btn-gray'" @click="changeDropdownValue(dropdown, availableItem.id)">{{ availableItem.name }}</button>
                </div>
            </div>
            
            <div style="font-size: 14px; padding-top:10px; display: inline-block;">Visar {{noOfItemsDisplayed}}/{{listItems.length}} {{ nameOfItems }}</div>
        </div>
        <div v-if="iconView == undefined || !iconView">     
            <div class="List-table-wrapper List-background-color" :style="{'height' : tableHeight, 'background-color': backgroundColor, 'border-color': borderColor }" style="overflow-y: auto;">
                <div :style="{ 'width': tableWidth }">
                    <div class="List-frozen-cols-content List-background-color" :style="{'background-color': backgroundColor, 'border-color': borderColor }">
                        <div class="List-col List-background-color" v-for="(col, index) in frozenColumns" :key="index" :style="{ 'width': col.width + 'px', 'text-align': col.align == undefined ? 'left' : col.align, 'z-index': 2, 'background-color': backgroundColor, 'border-color': borderColor  }" v-tooltip="col.title">
                            {{col.displayName}}
                            <ListFilter v-if="!col.hideFilter" :prop="col.name" :items="listItems" :id="id" :filterHeight="300" :filters="filters" @doFilterList="filterList"></ListFilter>
                        </div>
                        <div v-for="item in listItems" v-show="item.IncludedInFilter" :key="item.rowId">
                            <div v-for="(col,index) in frozenColumns" :key="index" :style="{ 'text-align': col.align == undefined ? 'left' : col.align, 'width': col.width + 'px', 'display': 'inline-block'}" class="List-row">
                                <div v-show="col.hideIfEdit == undefined || (col.hideIfEdit && !isEditing)">                                                                   
                                    <div v-if="col.isDelete"><i v-if="col.isNotDeletable == undefined || !item[col.isNotDeletable]" class="fa-solid fa-trash" @click="showDeletePopUp(item)"></i></div>                           
                                    <div v-else-if="col.isSendUserMessage && item.user_id != user.id && (item.is_active == undefined || item.is_active)" @click="sendUserMessage(item)"><i class="fa-solid fa-message"></i></div>
                                    <div v-else-if="col.isDay">{{ dayOfWeek[item[col.name]] }}</div>
                                    <div v-else-if="col.isBoolean" style="text-align: center">
                                        <i class="fa-solid fa-check" v-if="item[col.name]" v-tooltip="item[col.name + '_tooltip']"></i>
                                    </div>
                                    <a v-else-if="col.rowIcon" style="cursor:pointer; text-align: center" @click="col.function(item, col.name)" v-tooltip="item[col.name + '_tooltip']"><i :class="item[col.name]"></i></a>
                                    <a v-else-if="col.icon && col.function && col.onlyShowIfTrue == undefined" style="cursor:pointer; text-align: center" @click="col.function(item, col.name)" v-tooltip="item[col.name + '_tooltip']"><i :class="col.icon"></i></a>
                                    <div v-else-if="col.icon && col.function && col.onlyShowIfTrue == true">
                                        <a v-if="item[col.name]" style="cursor:pointer; text-align: center" @click="col.function(item, col.name)" v-tooltip="item[col.name + '_tooltip']"><i :class="col.icon"></i></a>
                                    </div>
                                    <div v-else-if="col.icon && !col.function"><i :class="col.icon"></i></div>
                                    <a v-else-if="col.trueFalseFunction != null" @click="col.function(item, col.name)">
                                        <i :class="col.trueFalseFunction.trueIcon" v-show="item[col.name]"></i>
                                        <i :class="col.trueFalseFunction.falseIcon" v-show="!item[col.name]"></i>
                                    </a>
                                    <div v-else-if="col.isTextEditorFormat" v-html-to-text="item[col.name]">
                                        
                                    </div>
                                    <div v-else :class="col.isNumber ? 'List-table-number' : ''" v-tooltip="item[col.name + '_tooltip']">{{ item[col.name] }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="List-background-color" :style="{'background-color': backgroundColor }">
                        <div v-for="(col, index) in stickyColumns" :key="index" :style="{ 'width': col.width + 'px', 'text-align': col.align == undefined ? 'left' : col.align, 'background-color': backgroundColor, 'border-color': borderColor }" class="List-col List-background-color" v-tooltip="col.title">
                            <span v-if="col.displayName.length > 0">{{col.displayName}}</span>
                            <span v-else>&nbsp;</span>
                            <ListFilter v-if="!col.hideFilter" :prop="col.name" :items="listItems" :id="id" :filterHeight="300" :isNumber="col.isNumber" :filters="filters" @doFilterList="filterList"></ListFilter>
                        </div>
                        <div v-for="item in listItems" v-show="item.IncludedInFilter" :key="item.rowId">
                            <div v-for="(col,index) in stickyColumns" :key="index" :style="{ 'text-align': col.align == undefined ? 'left' : col.align, 'width': col.width + 'px', 'display': 'inline-block' }" class="List-row">
                                <div v-show="col.hideIfEdit == undefined || (col.hideIfEdit && !isEditing)">
                                    <a v-if="col.trueFalseFunction != null" @click="col.function(item, col.name)">
                                        <i :class="col.trueFalseFunction.trueIcon" v-show="item[col.name]"></i>
                                        <i :class="col.trueFalseFunction.falseIcon" v-show="!item[col.name]"></i>
                                    </a>
                                    <div v-else-if="col.isDay">{{ dayOfWeek[item[col.name]] }}</div>
                                    <div v-else-if="col.isSendUserMessage && item.user_id != user.id" @click="sendUserMessage(item)"><i class="fa-solid fa-message"></i></div>      
                                    <a v-else-if="col.icon && col.function && col.onlyShowIfTrue == undefined" style="cursor:pointer; text-align: center" @click="col.function(item, col.name)" v-tooltip="item[col.name + '_tooltip']"><i :class="col.icon"></i></a>
                                    <div v-else-if="col.icon && col.function && col.onlyShowIfTrue == true">
                                        <a v-if="item[col.name]" style="cursor:pointer; text-align: center" @click="col.function(item, col.name)" v-tooltip="item[col.name + '_tooltip']"><i :class="col.icon"></i></a>
                                    </div>
                                    <div v-else-if="col.icon && !col.function"><i :class="col.icon"></i></div>
                                    <div v-else-if="col.isBoolean" style="text-align: center">
                                        <i class="fa-solid fa-check"  v-if="item[col.name]" v-tooltip="item[col.name + '_tooltip']"></i>
                                    </div>
                                    <div v-else-if="col.isTextEditorFormat" v-html-to-text="item[col.name]"></div>
                                    <div v-else :class="col.isNumber ? 'List-table-number' : ''" v-tooltip="item[col.name + '_tooltip']">{{ item[col.name] }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </div>
        <div v-else>
            <div v-for="(item,index) in listItems" :key="index" v-show="item.IncludedInFilter" class="List-iconview-item">
                <div v-for="(col, index) in columns" :key="index">
                <div class="List-iconview-item-header">
                    {{ col.displayName }}
                </div>
                <div class="List-iconview-item-value">
                    {{ item[col.name] != '' ? item[col.name] : '-' }}
                </div>
            </div>
            </div>
        </div>      
    </div>
    <div v-if="deleteItem != null">
        <YesNoPopUp :yes="remove" :no="cancelRemove" :text="'Är du säker på att du vill ta bort?'"></YesNoPopUp>       
    </div>
    <div v-if="userMessage != null">
        <UserMessageSender :userMessage="userMessage" :closeUserMessageSender="closeUserMessageSender"></UserMessageSender>
    </div> 
    <div v-if="showSortingWindow">
    <PopUp>
        <h2>Sortera Listan</h2>    
        <h5>Sortera efter</h5>
        <button class="btn" v-for="(item,index) in sorting" :key="index" @click="sortBy(item)" :class="sortedByColumn == null || sortedByColumn.name != item.name ? 'btn-blur' : ''">
            {{ item.displayName }}
        </button>
        <h5>Stigande/Fallande</h5>
        <button class="btn" @click="isAscending = true" :class="isAscending ? '': 'btn-blur'">Stigande</button>
        <button class="btn" @click="isAscending = false" :class="isAscending ? 'btn-blur': ''">Fallande</button>
        <div class="g-100 margin-top-15">
        <button class="btn float-right" @click="orderList">Sortera!</button>
        <button class="btn float-right btn-gray" @click="showSortingWindow = false">Avbryt</button>
        </div>
    </PopUp>
    </div>
</template>

<style scoped>

    h5{
        text-transform: uppercase;
        margin-bottom: 6px;
    }
    .List-sorted-by-list
    {
        position: absolute;
        min-width: 213px;
        z-index: 3;
        font-size:14px;
        margin-top: -3px;
        box-shadow: 1px 1px #ddd;
    }
    .List-sorted-by-list div{
        padding:8px 13px 0px 13px;
        cursor: pointer;
    }
    
    .List-sorted-by-list p{
       margin:0px;
       height: 1px;
       border-bottom: 1px solid #ddd;
       padding:3px 0px;
    }


    #List-sort-btn
    {
        margin-bottom: 3px;
        padding-left: 30px;
        padding-right: 30px;
    }
    .List-frozen-cols-content {
        position: sticky;
        left: 0px;
        float: left;
        z-index: 2;
        border-right: 1px dashed #ddd;
        color: black;
    }

    .List-frozen-col {
        display: inline-block;
    }

    .List-col {
        display: inline-block;
        position: sticky;
        top: 0px;
        border-bottom: 1px solid #ddd;
        font-weight: 700;
        padding: 6px 4px;
        color: black;
        height: 15px;
        overflow: hidden;
        line-height: 18px;
    }

    .List-row {
        padding: 6px 4px;
        overflow:hidden;
        height: 14px;
        line-height: 20px;
    }

    .List-search {
        float: right;
        width: 200px;
        font-size: 11pt;
        border: 1px solid #ddd;
        padding: 5px 0px 5px 10px;
        color: gray;
        margin: 0px 0px 3px 3px;
    }

    .List-table-wrapper {
        border-top: 1px solid #DDDDDD;
        border-bottom: 1px solid #DDDDDD;
        overflow-y: auto;
        margin-bottom: 20px;
        border-radius: 3px;
        font-size: 14px;     
    }

    .List-background-color
    {
        background-color: white;
    }

    .List-iconview-item
    {
        border: 1px solid #ddd;
        margin: 3px 0px;
        padding: 5px;
        background-color: white;
    }

    .List-iconview-item-header
    {
        font-size: 13px;
        font-weight: bold;
    }

    .List-iconview-item-value
    {
        padding: 0px 0px 4px 6px;
    }
</style>
